import {
    post,
    get,
    put
} from '../apiRequest';

/**
 * Saves a user by sending a POST request to the specified API endpoint.
 * @param {Object} user - The user object to be saved.
 * @returns {Promise} - A promise that resolves with the API response data.
 * @throws {Error} - Throws an error if the request fails.
 */
export const saveUser = async (user) => {
    const apiResponse = await post('api/user', {}, user).then(res => {
        return res;
    }).catch(error => {
        throw error;
    });

    return apiResponse;
}

/**
 * Disables a user by sending a PUT request to the specified API endpoint.
 * @param {number} id - The ID of the user to be disabled.
 * @returns {Promise} - A promise that resolves with the API response data.
 * @throws {Error} - Throws an error if the request fails.
 */
export const disableUser = async (id) => {
    const apiResponse = await put('api/user/disable/' + id, {}, {}).then(res => {
        return res;
    }).catch(error => {
        throw error;
    });

    return apiResponse;
}

/**
 * Activates a user by sending a PUT request to the specified API endpoint.
 * @param {number} id - The ID of the user to be activated.
 * @returns {Promise} - A promise that resolves with the API response data.
 * @throws {Error} - Throws an error if the request fails.
 */
export const activeUser = async (id) => {
    const apiResponse = await put('api/user/active/' + id, {}, {}).then(res => {
        return res;
    }).catch(error => {
        throw error;
    });

    return apiResponse;
}

/**
 * Retrieves all users by sending a GET request to the specified API endpoint.
 * @returns {Promise} - A promise that resolves with the API response data.
 * @throws {Error} - Throws an error if the request fails.
 */
export const getUsers = async () => {
    const apiResponse = await get('api/user', {}).then(res => {
        return res;
    }).catch(error => {
        throw error;
    });

    return apiResponse;
}