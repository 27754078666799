<template>
  <div style="margin: 1em">
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="showErrorMessage"
      @dismissed="showErrorMessage = false"
      >{{ errorMessage }}</b-alert
    >
    <b-alert
      variant="success"
      dismissible
      fade
      :show="showSuccessMessage"
      @dismissed="showSuccessMessage = false"
      >{{ successMessage }}</b-alert
    >

    <div>
      <user-form
        @callErrorMessage="callErrorMessage"
        @resetUserFields="resetUserFields"
        @setUserToEdit="setUserToEdit"
        @callSuccessMessage="callSuccessMessage"
        :user="user"
      />
    </div>
    <div>
      <user-grid
        @filterUsers="filterUsers"
        @callErrorMessage="callErrorMessage"
        @getAllUsers="getAllUsers"
        @callSuccessMessage="callSuccessMessage"
        @setUserToEdit="setUserToEdit"
        :users="users"
      />
    </div>
  </div>
</template>

<script>
import Form from "../components/form";
import Grid from "../components/grid";
import { getUsers as getUsersService } from "../../../../services/userService";

export default {
  data() {
    return {
      showErrorMessage: false,
      showSuccessMessage: false,
      errorMessage: "",
      successMessage: "",
      users: [],
      allUsers: [],
      user: {
        company: {
          description: "",
          companyId: undefined,
        },
      },
    };
  },
  components: {
    "user-form": Form,
    "user-grid": Grid,
  },
  methods: {
    /**
     * Sets an error message and displays it for a specified duration.
     * @param {string} message - The error message to be displayed.
     * @param {Function} callback - An optional callback function to be executed after displaying the error message.
     */
    callErrorMessage(message, callback) {
      var vm = this;

      vm.errorMessage = message;
      vm.showErrorMessage = true;

      setTimeout(() => {
        vm.showErrorMessage = false;
      }, 5000);

      if (callback) callback();
    },
    /**
     * Resets the user fields to their default values.
     * @param {Function} callback - An optional callback function to be executed after resetting the user fields.
     */
    resetUserFields(callback) {
      this.user = {
        company: {
          description: "",
          companyId: undefined,
        },
      };

      if (callback) callback();
    },
    /**
   * Filters users based on the provided text.
   * @param {string} text - The text to be used as filter criteria.
   * @returns {void} - Returns true if the user's full name, status, or username contains the provided text (case-insensitive)
   */
    filterUsers(text) {
      this.users = this.allUsers.filter((u) => {
        var enableUserText = u.enable ? "ativo" : "inativo";

        return (
          u.fullName.toUpperCase().includes(text.toUpperCase()) ||
          enableUserText.toUpperCase().startsWith(text.toUpperCase()) ||
          u.username.toUpperCase().includes(text.toUpperCase())
        );
      });
    },
    /**
    * Asynchronous function to display a success message for a certain period and execute callbacks if provided.
    * @param {string} message - The success message to be displayed.
    * @param {...function} callback - The callbacks to be executed after displaying the success message.
    */
    async callSuccessMessage(message, ...callback) {
      await this.getAllUsers().then(() => {
        var vm = this;

        vm.successMessage = message;
        vm.showSuccessMessage = true;

        setTimeout(() => {
          vm.showSuccessMessage = false;
        }, 5000);

        if (callback) callback.forEach((c) => c.apply());
      });
    },
    /**
    * Function to set all users and optionally execute callbacks.
    * @param {...function} callback - The callbacks to be executed after setting the user.
    */
    async getAllUsers(...callback) {
      const result = await getUsersService().then((res) => {
        return res.data;
      });

      this.users = result;
      this.allUsers = result;

      if (callback) callback.forEach((c) => c.apply());
    },
    /**
    * Function to set the user to edit and optionally execute callbacks.
    * @param {object} user - The user object to be edited.
    * @param {...function} callback - The callbacks to be executed after setting the user.
    */
    setUserToEdit(user, ...callback) {
      this.user = { ...user };

      if (callback) callback.forEach((c) => c.apply());
    },
  },
};
</script>

<style>
.card-header {
  background-color: black;
  color: white;
}
</style>