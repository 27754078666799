<template>
    <div>
        <b-overlay :show="isLoading" spinner-variant="warning" rounded="sm" opacity="0.99">

            <b-card class="mt-3" header="Consulta">
                <b-form>

                    <b-form-group id="user-filter">
                        <b-form-input v-model="filterText" id="user-filter-input" type="text" placeholder="Digite..." />
                    </b-form-group>
                </b-form>

                <b-table bordered :fields="fields" striped hover :items="users">
                    <template #cell(actions)="row">
                        <div class="d-flex justify-content-center">
                            <font-awesome-icon class="fa-xl mr-2" @click="editUser(row.item.id)" icon="fa-solid fa-pen-to-square" />

                            <font-awesome-icon v-if="row.item.enable" class="fa-xl" @click="openDisableModal(row.item.id)" icon="fa-solid fa-ban" />
                            <font-awesome-icon v-if="!row.item.enable" class="fa-xl" @click="activeUser(row.item.id)" icon="fa-solid fa-circle-check" />
                        </div>
                    </template>

                    <template #cell(enable)="row">
                        <p v-if="row.item.enable">Ativo</p>
                        <p v-else>Inativo</p>
                    </template>

                </b-table>
            </b-card>
        </b-overlay>


        <!--Inactive modal-->
        <div>
            <b-modal hide-backdrop ref="modal-inactive" id="modal-inactive" title="Desativar usuário" hide-footer static>
                <p class="my-4">Deseja mesmo desativar o usuário selecionado?</p>
                <div class="d-flex justify-content-between">
                    <b-button @click="$bvModal.hide('modal-inactive')" variant="outline-secondary" class="mt-3">Cancelar</b-button>
                    <b-button variant="danger" @click="disableUser(userIdToDisable)" class="mt-3">Confirmar</b-button>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
    import {
        disableUser as disableUserService,
        activeUser as activeUserService
    } from '../../../../../services/userService'

    export default {
        data() {
            return {
                fields: [
                    { key: 'fullName', label: 'Nome', sortable: true },
                    { key: 'username', label: 'Usuário', sortable: true },
                    { key: 'company.description', label: 'Empresa', sortable: true },
                    { key: 'enable', label: 'Status', sortable: true },
                    { key: 'actions', label: 'Ações', class: 'text-center' },
                ],
                isLoading: true,
                userIdToDisable: '',
                filterText: ''
            }
        },
        watch: {
            filterText(value) {
                this.$emit('filterUsers', value);
            },
        },
        props: ['users'],
        methods: {
            /**
             * Disables the loading state by setting the isLoading property to false.
             */
            disableLoading() {
                this.isLoading = false;
            },
            /**
             * Opens a modal to disable a user with the specified ID.
             * @param {number|string} id - The ID of the user to be disabled.
             */
            openDisableModal(id) {
                this.userIdToDisable = id;

                this.$refs['modal-inactive'].show();
            },
            /**
             * Asynchronously retrieves and emits a user object for editing.
             * @param {number|string} id - The ID of the user to be edited.
             */
            async editUser(id) {
                const user = await this.users.filter(u => u.id === id)[0];

                this.$emit("setUserToEdit", user);
            },
            /**
             * Asynchronously disables a user identified by the specified ID.
             * @param {number|string} id - The ID of the user to be disabled.
             */
            async disableUser(id) {
                this.$refs['modal-inactive'].hide();
                this.isLoading = true;

                await disableUserService(id).then(() => {
                    this.$emit("callSuccessMessage", "Usuário desativado com sucesso", this.disableLoading);
                }).catch(error => {
                    if (error.response.status != 204) {
                        this.$emit("callErrorMessage", "Erro na hora de desativar o usuário, contate o administrador do sistema", this.disableLoading);
                    }
                });
            },
            /**
             * Asynchronously activates a user identified by the specified ID.
             * @param {number|string} id - The ID of the user to be activated.
             */
            async activeUser(id) {
                this.isLoading = true;

                await activeUserService(id).then(() => {
                    this.$emit("callSuccessMessage", "Usuário ativado com sucesso", this.disableLoading);
                }).catch(error => {
                    if (error.response.status != 204) {
                        this.$emit("callErrorMessage", "Erro na hora de ativar o usuário, contate o administrador do sistema", this.disableLoading);
                    }
                });
            }
        },
        created() {
            // Emit an event to fetch all users and disable loading
            this.$emit("getAllUsers", this.disableLoading);
        },
    }
</script>

<style>
    .fa-pen-to-square {
        color: green;
        cursor: pointer;
    }

    .fa-ban {
        color: red;
        cursor: pointer;
    }

    .fa-circle-check {
        color: green;
        cursor: pointer;
    }
</style>