import {
    get as getRoles
} from '../apiRequest';

/**
 * Retrieves all roles by sending a GET request to the specified API endpoint.
 * @returns {Promise} - A promise that resolves with the API response data.
 */
export const getAllRoles = async () => {
    const apiResponse = await getRoles('api/role').then(res => {
        return res;
    });

    return apiResponse;
}