<template>
  <div>
    <b-overlay :show="isLoading" spinner-variant="warning" rounded="sm" opacity="0.99">
      <b-card class="mt-3" header="Cadastro">
        <b-form @submit.prevent="saveUser" novalidate>
          <input type="hidden" v-model="user.id" />
          <b-form-row>
            <b-col>
              <b-form-group
                id="input-group-1"
                label="Nome:"
                label-for="user-first-name"
              >
                <b-form-input
                  class="w-100"
                  id="user-first-name"
                  v-model="user.firstName"
                  type="text"
                  :state="validateState('firstName')"
                  placeholder="Insira o nome"
                  aria-describedby="input-1-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="input-1-live-feedback"
                  >Campo obrigatório</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                id="input-group-2"
                label="Sobrenome:"
                label-for="user-last-name"
              >
                <b-form-input
                  class="w-100"
                  id="user-last-name"
                  v-model="user.lastName"
                  type="text"
                  :state="validateState('lastName')"
                  placeholder="Insira o sobrenome"
                  aria-describedby="input-2-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="input-2-live-feedback"
                  >Campo obrigatório</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group
                id="input-group-3"
                label="Email:"
                label-for="user-email"
              >
                <b-form-input
                  class="w-100"
                  id="user-email"
                  v-model="user.email"
                  type="email"
                  :state="validateState('email')"
                  placeholder="Insira o e-mail"
                  aria-describedby="input-3-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="input-3-live-feedback"
                  >Email inválido</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-4"
                label="Usuario:"
                label-for="user-username"
              >
                <b-form-input
                  class="w-100"
                  id="user-username"
                  v-model="user.username"
                  type="text"
                  :state="validateState('username')"
                  :disabled="user.id ? true : false"
                  placeholder="Insira o usuario"
                  aria-describedby="input-4-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="input-4-live-feedback"
                  >Campo obrigatório</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="!user.id">
            <b-col>
              <b-form-group
                id="input-group-5"
                label="Senha:"
                label-for="user-password"
              >
                <b-form-input
                  class="w-100"
                  id="user-password"
                  v-model="user.password"
                  type="password"
                  :state="validateState('password')"
                  placeholder="Insira senha"
                  aria-describedby="input-5-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="input-5-live-feedback"
                  >A senha deve conter no mínimo 6
                  dígitos</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                id="input-group-6"
                label="Confirmar senha:"
                label-for="user-password-confirm"
              >
                <b-form-input
                  class="w-100"
                  id="user-password-confirm"
                  v-model="user.passwordConfirmed"
                  type="password"
                  :state="validateState('passwordConfirmed')"
                  placeholder="Confirmar senha"
                  aria-describedby="input-6-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="input-6-live-feedback"
                  >As senhas não coincidem</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col>
              <b-form-group
                id="input-group-7"
                label="Empresa"
                label-for="user-company"
              >
                <b-form-select
                  class="w-50"
                  id="user-company"
                  v-model="user.company.companyId"
                  :state="validateState('companyId')"
                  :options="companies"
                  aria-describedby="input-7-live-feedback"
                >
                  <template #first>
                    <b-form-select-option :value="undefined" disabled
                      >Selecione...</b-form-select-option
                    >
                  </template>
                </b-form-select>
                <b-form-invalid-feedback id="input-7-live-feedback"
                  >Preencha esse campo</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                id="input-group-8"
                label="Perfil"
                label-for="user-role"
              >
                <b-form-select
                  class="w-50"
                  id="user-role"
                  v-model="user.roleId"
                  :state="validateState('roleId')"
                  :options="roles"
                  aria-describedby="input-8-live-feedback"
                >
                  <template #first>
                    <b-form-select-option :value="undefined" disabled
                      >Selecione...</b-form-select-option
                    >
                  </template>
                </b-form-select>
                <b-form-invalid-feedback id="input-8-live-feedback"
                  >Preencha esse campo</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
          </b-form-row>

          <!-- Edit user mode-->
          <div class="d-flex justify-content-end">
            <b-button v-if="user.id" class="mr-3" @click="cancelEdit"
              >Cancelar</b-button
            >
            <b-button v-if="user.id" variant="success" type="submit"
              >Editar</b-button
            >
          </div>

          <!-- Save new user mode-->
          <b-button
            v-if="!user.id"
            class="float-right"
            variant="success"
            type="submit"
            >Salvar</b-button
          >
        </b-form>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { saveUser as saveUserService } from "../../../../../services/userService";
import { getAllRoles as getRolesService } from "../../../../../services/roleService";
import { getCompanies as getCompaniesService } from "../../../../../services/companyService";
import {
  required,
  requiredIf,
  email,
  minLength,
  sameAs,
} from "vuelidate/lib/validators";
export default {
  data() {
    return {
      companies: [],
      roles: [],
      isLoading: false,
    };
  },
  props: ["user"],
  validations() {
    const vm = this;
    return {
      user: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
        username: { required },
        password: {
          required: requiredIf(function () {
            return !vm.user.id;
          }),
          minLength: minLength(6),
        },
        passwordConfirmed: {
          required: requiredIf(function () {
            return !vm.user.id;
          }),
          sameAs: !vm.user.id ? sameAs("password") : {},
        },
        companyId: {
          required: requiredIf(function () {
            return !vm.user.company.companyId;
          }),
        },
        roleId: { required },
      },
    };
  },
  methods: {
    /**
     * Asynchronously fetches roles from the server and maps them to a format suitable for display.
     */
    async getRoles() {
      const roles = await getRolesService().then((resp) => {
        return resp.data;
      });

      this.roles = roles.map((r) => {
        return {
          text: r.name,
          value: r.id,
        };
      });
    },
    /**
     * Asynchronously fetches companies from the server and maps them to a format suitable for display.
     */
    async getCompanies() {
      const companies = await getCompaniesService().then((resp) => {
        return resp.data;
      });

      this.companies = companies.map((r) => {
        return {
          text: r.description,
          value: r.companyId,
        };
      });
    },
    /**
     * Validates the state of a specific field within the user object.
     * @param {string} name - The name of the field to validate.
     * @returns {boolean|null} - Returns a boolean indicating whether the field is valid, or null if the field is not dirty.
     */
    validateState(name) {
      const { $dirty, $error } = this.$v.user[name];
      return $dirty ? !$error : null;
    },
    /**
     * Disables the loading state by setting the isLoading property to false.
     */
    disableLoading() {
      this.isLoading = false;
    },
    /**
     * Resets user fields by emitting an event to trigger the reset action.
     */
    resetFields() {
      this.$emit("resetUserFields");
    },
    /**
     * Asynchronously saves the user data, handling validation and displaying appropriate messages.
     */
    async saveUser() {
      this.$v.user.$touch();
      if (this.$v.user.$anyError) {
        return;
      } else {
        this.isLoading = true;
        this.$v.$reset();

        await saveUserService(this.user)
          .then(() => {
            this.$emit(
              "callSuccessMessage",
              "Cadastro de usuário atualizado",
              this.disableLoading,
              this.resetFields
            );
          })
          .catch((error) => {
            if (error.response.status == 409) {
              this.$emit(
                "callErrorMessage",
                "Usuário ja cadastrado verifique o e-mail / nome de usuário",
                this.disableLoading
              );
            } else {
              this.$emit(
                "callErrorMessage",
                "Erro no cadastro de usuário, contate o administrador do sistema",
                this.disableLoading
              );
            }
          });
      }
    },
    /**
     * Cancels the current edit operation by resetting validation and emitting an event to reset user data.
     */
    cancelEdit() {
      this.$v.$reset();
      this.$emit("setUserToEdit", {
        company: {
          companyId: undefined,
          description: "",
        },
      });
    },
  },
  created() {
    this.getRoles();
    this.getCompanies();
  },
};
</script>